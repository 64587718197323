<template>
    <section class="content pl-2 pr-2">
        <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:tableSize="tableSize" ref="mainDataTable" v-if="showList"></CustomDataTable>
        <shipping-status v-if="!showList" :save-handler="saveHandler" :id="singleId"></shipping-status>
    </section>
</template>

<script>
    import Vue from 'vue';
    import CustomDataTable from "../table/CustomDataTable";
    import {isAdmin} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {ShippingService} from "@/services/shipping-service";
    import ShippingStatus from "@/components/app/shipping/ShippingStatus";
    let shippingService = new ShippingService();
    export default {
        name: 'ShippingStatuses',
        data: function(){
            return {
                tableSize: 'col-sm-12',
                tableDataUrl: this.$globalSettings.api.endpoints.shippingStatus.shippingStatusesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60px'},
                    {label: 'Name', name: 'name', orderable: true},
                    {label: 'Image', name: 'image', orderable: false, transform: ({data, name}) => data[name]? `<img src="${this.$apiBaseURL + '/' + data[name]}" class="img-sm" />`: '' },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],

                showList: false,
                singleId: 0,
            }
        },
        props: {
            mode:{
                type: String,
                default: () => 'list'
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            ShippingStatus,
            CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                //this.$router.push({name: 'App.ShippingStatus', params: { id: 0 }});
                this.showList = false;
                this.singleId = 0;
            },
            edit(data){
                //this.$router.push({name: 'App.ShippingStatus', params: { id: data.id }});
                this.showList = false;
                this.singleId = data.id;
            },
            saveHandler: async function () {
                this.showList = true;
                this.singleId = 0;
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this product Brand?")) {
                    let loader = this.$loading.show();
                    let response = await shippingService.deleteShippingStatus(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }
                    loader.hide();
                }
            }
        },
        mounted() {
            this.showList = this.mode === 'list';
            if (isAdmin() && this.showList) {
                this.columns.push({
                    label: '',
                    style: 'width: 60px',
                    component: CustomDataTableDropDownButton,
                    meta: {
                        items: [
                            {
                                label: 'Edit',
                                showLabel: true,
                                iconClass: 'fas fa-edit',
                                event: "click",
                                handler: this.edit,
                                dataPermissionConditions: [{column: 'editable', val: 1}],
                                conditions: [],
                            },
                            {
                                label: 'Delete',
                                showLabel: true,
                                iconClass: 'fas fa-trash',
                                event: "click",
                                handler: this.delete,
                                dataPermissionConditions: [{column: 'editable', val: 1}],
                                conditions: [],
                            },
                        ]
                    }
                });
                if(this.showAddButtons){
                    this.additionalButtons.push({
                        label: 'Add',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.add
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
