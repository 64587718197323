<template>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6 offset-3">
                        <div class="card card-secondary">
                            <div class="card-header">{{ formTitle }}</div>
                            <form @submit="saveForm">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col-sm-12">
                                            <label for="name">Name *</label>
                                            <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="formData.name" required>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <button type="button" class="btn btn-sm btn-outline-secondary" @click="saveHandler()">Cancel</button>
                                    <button type="submit" class="btn btn-sm btn-primary float-right"><i class="fas fa-save"></i> Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            </div><!--/. container-fluid -->
        </section>
</template>

<script>
    import Vue from 'vue'
    import {PaymentService} from "@/services/payment-service";

    let paymentService= new PaymentService();
    export default {
        name: 'PaymentMethod',
        data: function(){
            return {
                pageName: 'Payment Method',
                segments: [
                    { link: true, text: 'Payments', routeName: 'App.Payments' },
                    { link: true, text: 'Payment Methods', routeName: 'App.Payment.Methods' },
                    { link: false, text: 'Payment Method' },
                ],
                caThrees: [],
                formData: {
                    id: 0,
                    name: null,
                },
                formTitle: ''
            }
        },
        props: {
            id:{
                type: Number,
                default: () => 0
            },
            saveHandler: {
                type: Function,
                default: function () {}
            }
        },
        components: {
        },
        computed: {

        },
        methods:{
            async loadSavedData() {
                if (this.id !== 0) {
                    this.formData.id = this.id;
                    let loader = this.$loading.show();
                    let response = await paymentService.getPaymentMethod(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.paymentMethod;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.saveHandler();
                    }
                    loader.hide();
                }
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                paymentService.formDataPaymentMethod = this.formData;
                let response = await paymentService.savePaymentMethod();
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.saveHandler();
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        mounted() {
            this.formTitle = (this.id === 0? 'Add ': 'Update ') + this.pageName;
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
