import { render, staticRenderFns } from "./Dashboard_view.vue?vue&type=template&id=3b7cf757&scoped=true&"
import script from "./Dashboard_view.vue?vue&type=script&lang=js&"
export * from "./Dashboard_view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7cf757",
  null
  
)

export default component.exports