<template>
    <div>
        <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:show-pagination="showPagination" v-bind:show-per-page="showPerPage" v-bind:show-search="showSearch" ref="mainDataTable"></CustomDataTable>
        <ModalGeneral :title="printModal.title" :size="printModal.thermalPrint? 'modal-md': 'modal-lg'" :showModal="printModal.showModal" :hideModal="hidePrintModal" v-if="printModal.showModal">
            <div slot="modal-body">
                <print-form :form-type="printModal.formType" :form-category="printModal.formCategory" :id="printModal.id" :thermal-print="printModal.thermalPrint"></print-form>
            </div>
        </ModalGeneral>
        <ModalShipping :title="shippingModal.title" size="modal-lg" :showModal="shippingModal.showModal" :hideModal="hideShippingModal" :form-type="shippingModal.formType" :form-no="shippingModal.id" v-if="shippingModal.showModal"></ModalShipping>
        
        <ModalGeneral :title="shippingStatusesModal.title" size="modal-lg" :showModal="shippingStatusesModal.showModal" :hideModal="hideShippingStatusesModal" v-if="shippingStatusesModal.showModal">
            <div slot="modal-body">
                <shipping-statuses :mode="shippingStatusesModal.mode"></shipping-statuses>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";

    dayjs.extend(customParseFormat);

    import CustomDataTable from "../../../components/app/table/CustomDataTable";
    import CustomDataTableDataConditionButton from "../../../components/app/table/CustomDataTableDataConditionButton";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import ModalGeneral from "../../../components/app/dialog/ModalGeneral";
    import PrintForm from "../../../components/app/common/PrintForm";
    import ModalShipping from "../dialog/ModalShipping";
    import {getCompanyValByKey} from "@/helpers/companyHelper";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import {isAdmin} from "@/helpers/userHelper";
    import {isInvoiceThermalPrint} from "@/helpers/settingsHelper";
    import {SaleService} from "@/services/sale-service";
    import ShippingStatuses from "@/components/app/shipping/ShippingStatuses";

    let saleService = new SaleService();
    export default {
        name: "Sales",
        data: function () {
            return{
                sale: {},
                tableDataUrl: this.$globalSettings.api.endpoints.sale.salesDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                    {label: 'Amount', name: 'grand_total', orderable: true},
                    {label: 'Date', name: 'date', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name]) },
                    {label: 'Customer', name: 'customer_name', orderable: true},
                ],
                additionalButtons: [],

                printModal:{
                    title: 'Print',
                    showModal: false,
                    formType: this.$globalEnums.formTypes.Sale,
                    formCategory: this.$globalEnums.formCategories.Invoice,
                    id: null,
                    thermalPrint: false,
                },
                shippingModal:{
                    title: 'Shipping',
                    showModal: false,
                    formType: this.$globalEnums.formTypes.Sale,
                    id: null,
                },
                shippingStatusesModal:{
                    title: 'Shipping Statuses',
                    showModal: false,
                    mode: 'list',
                },
            }
        },
        props: {
            showRef:{
                type: Boolean,
                default: () => true
            },
            showType:{
                type: Boolean,
                default: () => true
            },
            showShippingStatus:{
                type: Boolean,
                default: () => true
            },
            showPerPage:{
                type: Boolean,
                default: () => true
            },
            showSearch:{
                type: Boolean,
                default: () => true
            },
            searchFilters:{
                type: Object,
                default: () => ({
                    search: '',
                    length: 10,
                    column: 'date',
                    dir: 'desc',
                    type: -1
                })
            },
            showPagination: {
                type: Boolean,
                default: ()=> true
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
            showPrintButtons:{
                type: Boolean,
                default: () => true
            },
            showCheckmarkButtons:{
                type: Boolean,
                default: () => true
            },
            showViewButton:{
                type: Boolean,
                default: () => true
            },
            showEditButton:{
                type: Boolean,
                default: () => true
            },
            showCreateInvoiceButton:{
                type: Boolean,
                default: () => true
            },
            showCreateReturnButton:{
                type: Boolean,
                default: () => true
            },
            showPaymentsButton:{
                type: Boolean,
                default: () => true
            },
            showDeleteButton:{
                type: Boolean,
                default: () => true
            },
            showShippingButton:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            ShippingStatuses,
            ModalShipping,
            PrintForm,
            ModalGeneral,
            CustomDataTable
        },
        computed: {

        },
        methods: {
            getCompanyValByKey,
            shippingStatuses(){
                //this.$router.push({name: 'App.ShippingStatuses'});
                this.shippingStatusesModal.showModal = true;
            },
            order(){
                this.$router.push({name: 'App.Sale', params: { id: 0, type: this.$globalEnums.formCategories.Order }});
            },
            invoice(){
                this.$router.push({name: 'App.Sale', params: { id: 0, type: this.$globalEnums.formCategories.Invoice }});
            },
            return(){
                this.$router.push({name: 'App.Sale', params: { id: 0, type: this.$globalEnums.formCategories.Return }});
            },
            edit(data){
                this.$router.push({name: 'App.Sale', params: { id: data.id }});
            },
            payments(data){
                this.$router.push({name: 'App.Payments.Invoice', params: { id: data.id, type: data.type === this.$globalEnums.formCategories.Invoice? this.$globalEnums.paymentTypes.Sale: this.$globalEnums.paymentTypes.SalesReturn }});
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this sale?")) {
                    let loader = this.$loading.show();

                    let deleted = await saleService.deleteSale(data.id);
                    if (deleted.isSuccess) {
                        Vue.$toast.open({message: deleted.message, type: 'success'});
                        this.$refs.mainDataTable.loadTable();
                    } else {
                        Vue.$toast.open({message: deleted.message, type: 'error'});
                    }
                    loader.hide();
                }
            },
            createInvoiceFromOrder(data){
                this.$router.push({name: 'App.Sale', params: { id: 0, type: this.$globalEnums.formCategories.Invoice, refForm: { id: data.id, type: this.$globalEnums.formCategories.Order } }});
            },
            createReturnFromInvoice(data){
                this.$router.push({name: 'App.Sale', params: { id: 0, type: this.$globalEnums.formCategories.Return, refForm: { id: data.id, type: this.$globalEnums.formCategories.Invoice } }});
            },
            print(data){
                this.printModal.thermalPrint = false;
                if(data.type === this.$globalEnums.formCategories.Invoice && isInvoiceThermalPrint()){
                    this.printModal.thermalPrint = true;
                }
                //sale "type" is "formCategory" on the frontend
                this.printModal.formCategory = data.type;
                this.printModal.showModal = true;
                this.printModal.id = data.id;
            },
            hidePrintModal(){
                this.printModal.showModal = false;
            },
            showShippingModal(data){
                this.shippingModal.id = data.id;
                this.shippingModal.showModal = true;
            },
            hideShippingModal(){
                this.shippingModal.showModal = false;
                this.$refs.mainDataTable.loadTable();
            },
            hideShippingStatusesModal(){
                this.shippingStatusesModal.showModal = false;
            },
        },
        mounted() {
            //this.$loading.show({zIndex: 2000});

            if(this.showRef){
                this.columns.push({label: 'Ref#', name: 'ref', orderable: true});
            }
            if(this.showType){
                this.columns.push({label: 'Type', name: 'type', orderable: true, transform: ({data, name}) => Object.keys(this.$globalEnums.formCategories).find(key => this.$globalEnums.formCategories[key] === data[name])});
            }
            if(this.showShippingStatus){
                this.columns.push({label: 'Shipping', name: 'shipping_status', orderable: true, transform: ({data, name}) => data[name]? data[name].name: '' });
            }

            if(this.showViewButton){
                this.columns.push({label: '', orderable: false, classes: {'btn': true, 'btn-outline-secondary': true, 'btn-sm': true,}, style: 'width: 20px', event: "click", handler: this.print, meta: { label: 'View', showLabel: false, iconClass: 'fas fa-search', dataPermissionConditions: [{column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'sale_order_view'}, {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'sale_invoice_view'}, {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'sale_return_view'}], editableCheck: false }, component: CustomDataTableDataConditionButton });
            }

            let dropdownButtons = [];
            if(this.showEditButton){
                dropdownButtons.push({
                    label: 'Edit',
                    showLabel: true,
                    iconClass: 'fas fa-edit',
                    event: "click",
                    handler: this.edit,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'sale_order_edit'},
                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'sale_invoice_edit'},
                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'sale_return_edit'}
                    ],
                    conditions: [],
                });
            }
            if(this.showCreateInvoiceButton){
                dropdownButtons.push({
                    label: 'Create Invoice',
                    showLabel: true,
                    iconClass: 'fas fa-plus',
                    event: "click",
                    handler: this.createInvoiceFromOrder,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'sale_invoice_create'},
                    ],
                    conditions: [],
                });
            }
            if(this.showPaymentsButton){
                dropdownButtons.push({
                    label: 'Payments',
                    showLabel: true,
                    iconClass: 'fas fa-dollar-sign',
                    event: "click",
                    handler: this.payments,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: this.$globalSettings.permissions.payment.payment_list},
                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: this.$globalSettings.permissions.payment.payment_list}
                    ],
                    conditions: [],
                });
            }
            if(this.showShippingButton){
                dropdownButtons.push({
                    label: 'Shipping',
                    showLabel: true,
                    iconClass: 'fas fa-truck-container',
                    event: "click",
                    handler: this.showShippingModal,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: this.$globalSettings.permissions.sale.sale_shipping_management}
                    ],
                    conditions: [],
                });
            }
            if(this.showCreateReturnButton){
                dropdownButtons.push({
                    label: 'Create Return',
                    showLabel: true,
                    iconClass: 'fas fa-undo',
                    event: "click",
                    handler: this.createReturnFromInvoice,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'sale_return_create'},
                    ],
                    conditions: [],
                });
            }
            if(this.showDeleteButton){
                dropdownButtons.push({
                    label: 'Delete',
                    showLabel: true,
                    iconClass: 'fas fa-trash',
                    event: "click",
                    handler: this.delete,
                    dataPermissionConditions: [
                        {column: 'type', val: this.$globalEnums.formCategories.Order, permission: 'sale_order_delete'},
                        {column: 'type', val: this.$globalEnums.formCategories.Invoice, permission: 'sale_invoice_delete'},
                        {column: 'type', val: this.$globalEnums.formCategories.Return, permission: 'sale_return_delete'}
                    ],
                    conditions: [],
                });
            }
            if(dropdownButtons.length > 0) {
                this.columns.push(
                    {
                        label: '',
                        style: 'width: 60px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: dropdownButtons
                        }
                    }
                );
            }

            if(this.showAddButtons) {
                if (isAdmin()) {
                    this.additionalButtons.push({
                        label: 'Shipping Statuses',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-shipping-fast',
                        click: this.shippingStatuses
                    });
                }
                if (getPermissionByKey(this.$globalSettings.permissions.sale.sale_return_create)) {
                    this.additionalButtons.push({
                        label: 'Return',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.return
                    });
                }
                if (getPermissionByKey(this.$globalSettings.permissions.sale.sale_invoice_create)) {
                    this.additionalButtons.push({
                        label: 'Invoice',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.invoice
                    });
                }
                if (getPermissionByKey(this.$globalSettings.permissions.sale.sale_order_create)) {
                    this.additionalButtons.push({
                        label: 'Order',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.order
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>