<template>
    <div v-if="formData" id="print_area">
        <print-header :title="paymentType + ' Voucher'" :date="formData.payment_date" :serial-number="formData.id"></print-header>
<!--        <div v-if="(type === paymentTypes.Receive || type === paymentTypes.Sale || type === paymentTypes.Pay || type === paymentTypes.Purchase) && formData.payment_transactions.length > 0">
            <div class="row">
                <div class="col-sm-6">
                    <div class="text-bold">Date</div>
                    <div class="text-sm">{{ formData.payment_date }}</div>
                </div>
                <div class="col-sm-6 text-right">
                    <div class="text-bold">Serial #</div>
                    <div class="text-sm">{{ formData.id }}</div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-2 mt-2">
                    {{ type === paymentTypes.Receive || type === paymentTypes.Sale? 'Received from': 'Paid to' }}:
                </div>
                <div class="col-4 mt-2 border-dashed-bottom">
                    {{formData.payment_transactions[0].from_name}}
                </div>
                <div class="col-1 mt-2">Amount:</div>
                <div class="col-5 mt-2 border-dashed-bottom">{{ getCurrencyFormattedVal(formData.amount) }}</div>
                <div class="col-2 mt-2">
                   In Words:
                </div>
                <div class="col-10 mt-2 border-dashed-bottom"></div>
                <div class="col-2 mt-2">For:</div>
                <div class="col-10 mt-2 border-dashed-bottom">{{ formData.description }}</div>
            </div>
        </div>-->
        <div class="row mb-2">
            <div class="col-sm-2">
                <span>Serial #:</span>
                <span class="text-bold"> {{ formData.id }}</span>
            </div>
            <div class="col-sm-3">
                <span>Date:</span>
                <span class="text-bold"> {{ formData.payment_date }}</span>
            </div>
            <div class="col-sm-7">
                <div class="row">
                    <div class="col-sm-6">
                        <span>Created:</span>
                        <span class="text-bold"> {{ formData.created_at }}</span>
                    </div>
                    <div class="col-sm-6">
                        <span>Updated:</span>
                        <span class="text-bold"> {{ formData.updated_at }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="formData.payment_transactions.length > 0">
            <div class="row">
                <div class="col-sm-12">
                    <table class="table table-bordered table-sm mt-2">
                        <thead>
                        <tr>
                            <th style="width: 40%;">From</th>
                            <th class="text-right" style="width: 20%;">Amount</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{formData.payment_transactions[0].from_name}}</td>
                            <td class="text-right">{{ getCurrencyFormattedVal(formData.amount) }}</td>
                            <td>{{ formData.description }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-sm-12">
                    <table class="table table-bordered table-sm mt-2">
                        <thead>
                        <tr>
                            <th style="width: 40%;">To</th>
                            <th class="text-right" style="width: 20%;">Amount</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="transaction in formData.payment_transactions" :key="transaction.id">
                            <td>{{ transaction.to_name }}</td>
                            <td class="text-right">{{ getCurrencyFormattedVal(transaction.amount) }}</td>
                            <td>{{ transaction.to_description }}</td>
                        </tr>
                        <tr>
                            <td class="text-right">Total</td>
                            <td class="text-right">{{ getCurrencyFormattedVal(toTotal) }}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>Total Amount: <span class="text-bold">{{ getCurrencyFormattedVal(formData.amount) }}</span></div>
            <div>Payment Method: <span class="text-bold">{{ formData.payment_method.name }}</span></div>
        </div>

        <button type="button" class="btn btn-sm btn-secondary mt-5 d-print-none" v-on:click="printDiv('print_area')"><i class="fas fa-print"></i></button>
    </div>
</template>

<script>
    import Vue from 'vue';
    import PrintHeader from "./PrintHeader";
    import {printDiv} from "@/helpers/printHelper";
    import {getDateToday, convertUTCToLocalWithFormat, getDateFormattedVal} from "@/helpers/dateHelper";
    import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
    import {PaymentService} from "@/services/payment-service";
    let paymentService = new PaymentService();
    export default {
        name: "PrintPayment",
        data: function() {
            return {
                paymentTypes: this.$globalEnums.paymentTypes,
                formData: {
                    id: 0,
                    amount: null,
                    payment_date: getDateToday(),
                    ref: null,
                    invoice_no: null,
                    type: null,
                    payment_method_id: null,
                    cheque_no: null,
                    cheque_date: getDateToday(),
                    cheque_bank: null,
                    description: null,
                    image: null,
                    payment_transactions: [],
                },
                title: '',
            }
        },
        props:{
            type:{
                type: Number,
                default: () => 1
            },
            id:{
                type: Number,
                default: () => null
            },
        },
        computed: {
            paymentType: function(){
                return Object.keys(this.paymentTypes).find(key => this.paymentTypes[key] === this.type);
            },
            toTotal: function () {
                let total = 0;
                if(this.formData.payment_transactions.length > 0){
                    this.formData.payment_transactions.forEach((payment_transaction) => {
                        total += Number(payment_transaction.amount);
                    });
                }
                return total;
            }
        },
        components: {PrintHeader},
        methods:{
            printDiv,
            getCurrencyFormattedVal,
            loadSavedData: async function () {
                let loader = this.$loading.show();
                let response = await paymentService.getPayment(this.id, 'view');
                if (response.isSuccess) {
                    this.title = this.paymentType;
                    this.formData = response.payment;

                    this.formData.payment_transactions.forEach((payment_transaction, index) => {
                        payment_transaction['sl'] = index + 1;
                    });

                    this.formData.payment_date = getDateFormattedVal(response.payment.payment_date);
                    this.formData.created_at = convertUTCToLocalWithFormat(response.payment.created_at);
                    this.formData.updated_at = convertUTCToLocalWithFormat(response.payment.updated_at);
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        async mounted() {
            await this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>