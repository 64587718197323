<template>
    <ModalGeneral :title="title" size="modal-lg" :showModal="showModal" :hideModal="hideModal" v-if="showModal">
        <div slot="modal-body">
            <Shipping :form-type="formType" :formNo="formNo"></Shipping>
        </div>
    </ModalGeneral>
</template>

<script>
    import ModalGeneral from "./ModalGeneral";
    import Shipping from "../shipping/Shipping";
    export default {
        name: "ModalShipping",
        data: function() {
            return {

            }
        },
        props: {
            formType:  {
                type: Number,
                default: () => this.$globalEnums.formTypes.Sale
            },
            formNo:  {
                type: Number,
                default: () => null
            },
            showModal:  {
                type: Boolean,
                default: () => false
            },
            size:  {
                type: String,
                default: () => 'modal-md'
            },
            title:  {
                type: String,
                default: () => ''
            },
            hideModal: {
                type: Function,
                default:() => {}
            },
            showFooter: {
                type: Boolean,
                default: () => false
            },
            showFooterCloseBtn: {
                type: Boolean,
                default: () => false
            }
        },
        components: {Shipping, ModalGeneral}
    }
</script>

<style scoped>

</style>