<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6 offset-3">
                    <div class="card card-secondary">
                        <div class="card-header">{{ formTitle }}</div>
                        <ValidationObserver v-slot="{ invalid }">
                            <form @submit="saveForm">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col-sm-12">
                                            <ValidationProvider v-slot="{ errors }" rules="required">
                                                <label for="name">Name *</label>
                                                <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="formData.name" required>
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <button type="button" class="btn btn-sm btn-outline-secondary" @click="saveHandler()">Cancel</button>
                                    <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>


        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    import Vue from 'vue'
    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    import {ShippingService} from "@/services/shipping-service";
    let shippingService = new ShippingService();
    export default {
        name: 'ShippingStatus',
        data: function(){
            return {
                pageName: 'Shipping Status',

                upload_image: null,
                formData: {
                    id: 0,
                    name: null,
                },
                formTitle: ''
            }
        },
        props: {
            id:{
                type: Number,
                default: () => 0
            },
            saveHandler: {
                type: Function,
                default: function () {}
            }
        },
        components: {
            ValidationObserver, ValidationProvider
        },
        computed: {

        },
        methods:{
            async loadSavedData() {
                if (this.id !== 0) {
                    this.formData.id = this.id;
                    let loader = this.$loading.show();
                    let response = await shippingService.getShippingStatus(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.shippingStatus;
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.saveHandler();
                    }
                    loader.hide();
                }
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                shippingService.formDataShippingStatus = this.formData;
                let response = await shippingService.saveShippingStatus();
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.saveHandler();
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        mounted() {
            this.formTitle = (this.id === 0? 'Add ': 'Update ') + this.pageName;
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
