<template>
    <div>
        <section class="content">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:show-pagination="showPagination" v-bind:show-per-page="showPerPage" v-bind:show-search="showSearch" ref="mainDataTable"></CustomDataTable>
        </section>
        <!-- /.content -->
        <ModalGeneral :title="printModal.title" size="modal-lg" :showModal="printModal.showModal" :hideModal="hidePrintModal" v-if="printModal.showModal">
            <div slot="modal-body">
                <print-payment :type="printModal.type" :id="printModal.id"></print-payment>
            </div>
        </ModalGeneral>

        <ModalGeneral :title="accountsModal.title" size="modal-lg" :showModal="accountsModal.showModal" :hideModal="accountsModalHide" v-if="accountsModal.showModal">
            <div slot="modal-body">
                <accounts :mode="accountsModal.mode"></accounts>
            </div>
        </ModalGeneral>
        <ModalGeneral :title="paymentMethodsModal.title" size="modal-lg" :showModal="paymentMethodsModal.showModal" :hideModal="paymentMethodsModalHide" v-if="paymentMethodsModal.showModal">
            <div slot="modal-body">
                <payment-methods :mode="paymentMethodsModal.mode"></payment-methods>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
    import PaymentMethods from "@/components/app/payment/PaymentMethods";
    import Accounts from "@/components/app/account/Accounts";
    import PrintPayment from "@/components/app/common/PrintPayment";
    import ModalGeneral from "@/components/app/dialog/ModalGeneral";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import CustomDataTableButton from "@/components/app/table/CustomDataTableButton";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import CustomDataTable from "@/components/app/table/CustomDataTable";
    import {PaymentService} from "@/services/payment-service";
    import Vue from "vue";
    import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
    let paymentService = new PaymentService();
    export default {
        name: "Payments",
        data: function() {
            return {
                payment: {},
                tableDataUrl: this.$globalSettings.api.endpoints.payment.paymentsDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 60'},
                    {label: 'Date', name: 'payment_date', orderable: true, transform: ({data, name}) => convertUTCToLocalWithFormat(data[name]) },
                    {label: 'Amount', name: 'amount', orderable: true},
                ],
                additionalButtons: [],

                printModal:{
                    title: 'Print',
                    showModal: false,
                    type: 1,
                    id: null
                },
                accountsModal:{
                    title: 'Accounts',
                    showModal: false,
                    mode: 'list',
                },
                paymentMethodsModal:{
                    title: 'Payment Methods',
                    showModal: false,
                    mode: 'list',
                },
            }
        },
        props: {
            showRef:{
                type: Boolean,
                default: () => true
            },
            showType:{
                type: Boolean,
                default: () => true
            },
            showInvoice:{
                type: Boolean,
                default: () => true
            },
            showMethod:{
                type: Boolean,
                default: () => true
            },
            showPerPage:{
                type: Boolean,
                default: () => true
            },
            showSearch:{
                type: Boolean,
                default: () => true
            },
            searchFilters:{
                type: Object,
                default: () => ({
                    search: '',
                    length: 10,
                    column: 'payment_date',
                    dir: 'desc'
                })
            },
            showPagination: {
                type: Boolean,
                default: ()=> true
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
            showPrintButtons:{
                type: Boolean,
                default: () => true
            },
            showViewButton:{
                type: Boolean,
                default: () => true
            },
            showEditButton:{
                type: Boolean,
                default: () => true
            },
            showDeleteButton:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            PaymentMethods,
            Accounts,
            PrintPayment,
            ModalGeneral,
            CustomDataTable
        },
        methods: {
            receive(){
                this.$router.push({name: 'App.Payment', params: { id: 0, type: this.$globalEnums.paymentTypes.Receive }});
            },
            pay(){
                this.$router.push({name: 'App.Payment', params: { id: 0, type: this.$globalEnums.paymentTypes.Pay }});
            },
            reconciliation(){
                this.$router.push({name: 'App.Payment', params: { id: 0, type: this.$globalEnums.paymentTypes.Reconciliation }});
            },
            journal(){
                this.$router.push({name: 'App.Payment', params: { id: 0, type: this.$globalEnums.paymentTypes.JournalEntry }});
            },
            edit(data){
                this.$router.push({name: 'App.Payment', params: { id: data.id }});
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this payment?")) {
                    let loader = this.$loading.show();
                    let response = await paymentService.delete(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                    }
                    Vue.$toast.open({message: response.message, type: response.status});
                    loader.hide();
                }
            },
            printPayment(data){
                this.printModal.type = data.type;
                this.printModal.id = data.id;
                this.printModal.showModal = true;
            },
            hidePrintModal(){
                this.printModal.showModal = false;
            },
            accountsModalShow(){
                this.accountsModal.showModal = true;
            },

            paymentMethodsModalShow(){
                this.paymentMethodsModal.showModal = true;
            },
            accountsModalHide(){
                this.accountsModal.showModal = false;
            },
            paymentMethodsModalHide(){
                this.paymentMethodsModal.showModal = false;
            },
        },
        mounted() {
            if(this.showRef){
                this.columns.push({label: 'Ref#', name: 'ref', orderable: true});
            }
            if(this.showType){
                this.columns.push({label: 'Type', name: 'type', orderable: true, transform: ({data, name}) => Object.keys(this.$globalEnums.paymentTypes).find(key => this.$globalEnums.paymentTypes[key] === data[name]) });
            }
            if(this.showInvoice){
                this.columns.push({label: 'Invoice#', name: 'invoice_no', orderable: true});
            }
            if(this.showMethod){
                this.columns.push({label: 'Method', name: 'payment_method_name', orderable: true});
            }
            if(this.showViewButton){
                this.columns.push({label: '', orderable: false, classes: {'btn': true, 'btn-outline-secondary': true, 'btn-sm': true,}, style: 'width: 62px', event: "click", handler: this.printPayment, meta: { label: 'View', showLabel: false, iconClass: 'fas fa-search', editableCheck: false }, component: getPermissionByKey(this.$globalSettings.permissions.payment.payment_view)? CustomDataTableButton: null });
            }

            let dropdownButtons = [];
            if(this.showEditButton){
                dropdownButtons.push({
                    label: 'Edit',
                    showLabel: true,
                    iconClass: 'fas fa-edit',
                    event: "click",
                    handler: this.edit,
                    dataPermissionConditions: [],
                    conditions: [this.$globalSettings.permissions.payment.payment_edit],
                });
            }
            if(this.showDeleteButton){
                dropdownButtons.push({
                    label: 'Delete',
                    showLabel: true,
                    iconClass: 'fas fa-trash',
                    event: "click",
                    handler: this.delete,
                    dataPermissionConditions: [],
                    conditions: [this.$globalSettings.permissions.payment.payment_delete],
                });
            }
            if(dropdownButtons.length > 0) {
                this.columns.push(
                    {
                        label: '',
                        style: 'width: 60px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: dropdownButtons
                        }
                    }
                );
            }

            if(this.showAddButtons) {
                if (getPermissionByKey(this.$globalSettings.permissions.paymentMethod.paymentMethod_list)) {
                    this.additionalButtons.push({
                        label: 'Methods',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-coins',
                        click: this.paymentMethodsModalShow
                    });
                }
                if (getPermissionByKey(this.$globalSettings.permissions.payment.payment_create)) {
                    this.additionalButtons.push({
                        label: 'Journal',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-arrows-alt-h',
                        click: this.journal
                    });
                    this.additionalButtons.push({
                        label: 'Reconciliation',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-retweet',
                        click: this.reconciliation
                    });
                    this.additionalButtons.push({
                        label: 'Pay',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-level-up-alt',
                        click: this.pay
                    });
                    this.additionalButtons.push({
                        label: 'Receive',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-level-down-alt',
                        click: this.receive
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>