import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
//import {getOwnUserID} from "@/helpers/userHelper";

export class ShippingService{
    formDataShippingStatus = {};
    formDataShipping = {};
    constructor() {
        this.formDataShippingStatus = {};
        this.formDataShipping = {}
    }

    //shipping status
    async searchShippingStatus(fetch){
        let shippingStatuses = Vue.prototype.$store.getters['common/shippingStatuses'];
        if(shippingStatuses.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shippingStatus.shippingStatuses)
                .then((response) => {
                    if(response.data.status === 'success'){
                        shippingStatuses = response.data.shippingStatuses;
                        Vue.prototype.$store.dispatch('common/setShippingStatuses', {value: shippingStatuses});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, shippingStatuses: response.data.shippingStatuses};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', shippingStatuses: []};
                });
        }
        return {isSuccess: true, message: '', shippingStatuses: shippingStatuses};
    }

    async getShippingStatus(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shippingStatus.shippingStatus, {id: id, mode: mode})
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, shippingStatus: response.data.shippingStatus};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', shippingStatus: null};
            });
    }

    async saveShippingStatus(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shippingStatus.shippingStatusSave, this.formDataShippingStatus).then(async (response) => {
            await this.searchShippingStatus(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, shippingStatus_id: response.data.id};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', shippingStatus_id: null};
        });
    }

    async deleteShippingStatus(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shippingStatus.shippingStatusDelete, {
            id: id
        })
            .then(async (response) => {
                await this.searchShippingStatus(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //shippings
    async searchShipping(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shipping.shippings, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, shippings: response.data.shippings};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', shippings: []};
            });
    }
    async saveShipping(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shipping.shippingSave, this.formDataShipping)
            .then(async (response) => {
            return {isSuccess: response.data.status === 'success', message: response.data.msg, id: response.data.id};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', id: null};
        });
    }
    async deleteShipping(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.shipping.shippingDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}