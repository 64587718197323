<template>
    <div class="row">
        <div class="col-sm-12" v-if="formEnabled">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit="saveForm">
                    <div class="card">
                        <div class="card-header">{{ formTitle }}
                            <button type="button" class="btn btn-xs btn-outline-secondary float-right"
                                    v-on:click="closeForm()"><i class="fas fa-times"></i></button>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-sm-4">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <label for="shipping_status_id">Status *</label>
                                        <select class="form-control" id="shipping_status_id"
                                                v-model="formData.shipping_status_id" required>
                                            <option v-for="shippingStatus in shippingStatuses" :key="shippingStatus.id"
                                                    :value="shippingStatus.id">{{ shippingStatus.name }}
                                            </option>
                                        </select>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group col-sm-4">
                                    <ValidationProvider v-slot="{ errors }" rules="required" v-if="isAdmin()">
                                        <label for="user_id">User *</label>
                                        <select class="form-control" id="user_id" v-model="formData.user_id" required>
                                            <option v-for="user in users" :key="user.id" :value="user.id">{{
                                                    user.name
                                                }}
                                            </option>
                                        </select>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="name">Details</label>
                                    <input type="text" class="form-control" id="name" placeholder="Optional"
                                           v-model="formData.status_details">
                                </div>
                            </div>
                            <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i
                                    class="fas fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Shipping History
                    <button type="button" class="btn btn-xs btn-outline-primary float-right"
                            v-on:click="showForm('add', null)"><i class="fas fa-plus"></i></button>
                </div>
                <div class="card-body">
                    <div v-for="shipping in shippings" :key="shipping.id" class="row shipping-row">
                        <div :class="getPermissionByKey($globalSettings.permissions.sale.sale_shipping_management)? 'col-sm-9': 'col-sm-12'">
                            <div><span class="text-bold">{{ shipping.shipping_status.name }}</span> by <span
                                    class="text-sm font-italic">{{ shipping.user.name }}</span> on <span
                                    class="text-sm font-italic">{{
                                    convertUTCToLocalWithFormat(shipping.updated_at)
                                }}</span></div>
                            <div class="text-sm">{{ shipping.status_details }}</div>
                        </div>
                        <div class="col-sm-3 text-right"
                             v-if="getPermissionByKey($globalSettings.permissions.sale.sale_shipping_management)">
                            <button type="button" class="btn btn-sm btn-outline-primary mr-2"
                                    v-on:click="edit(shipping)"><i class="fas fa-edit"></i></button>
                            <button type="button" class="btn btn-sm btn-outline-danger" v-on:click="deleteShipping(shipping)"><i
                                    class="fas fa-trash"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';

import {getOwnUserID, isAdmin} from "@/helpers/userHelper";
import {getPermissionByKey} from "@/helpers/userHelper";
import {convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
import {ShippingService} from "@/services/shipping-service";
import {UserService} from "@/services/user-service";

let shippingService = new ShippingService();
let userService = new UserService();
extend('required', {
    ...required,
    message: 'This field is required'
});

export default {
    name: "Shipping",
    data: function () {
        return {
            formTitle: 'Add',
            formEnabled: false,

            shippings: [],
            shippingStatuses: [],
            users: [],

            formData: {
                id: 0,
                status_details: null,
                sale_id: null,
                shipping_status_id: null,
                user_id: getOwnUserID(),
            },
        }
    },
    props: {
        formType: {
            type: Number,
            default: () => this.$globalEnums.formTypes.Sale
        },
        formNo: {
            type: Number,
            default: () => null
        },
        addEnabled: {
            type: Boolean,
            default: () => true
        },
    },
    components: {
        ValidationObserver, ValidationProvider
    },
    methods: {
        isAdmin, getPermissionByKey, convertUTCToLocalWithFormat,
        loadShippingStatuses: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await shippingService.searchShippingStatus(false);
            if (response.isSuccess) {
                this.shippingStatuses = response.shippingStatuses;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        loadUsers: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await userService.getUsers(false);
            if (response.isSuccess) {
                this.users = response.users;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        loadFormShippingStatuses: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await shippingService.searchShipping({
                form_id: this.formNo,
                sortBy: 'created_at',
                sort: 'desc'
            });
            if (response.isSuccess) {
                this.shippings = response.shippings;
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        showForm(type, data) {
            if (type === 'add') {
                this.formData = {
                    id: 0,
                    status_details: null,
                    sale_id: null,
                    shipping_status_id: null,
                    user_id: getOwnUserID(),
                };
                this.formTitle = 'Add';
            } else {
                this.formData = data;
                this.formTitle = 'Edit';
            }
            this.formEnabled = true;
        },
        closeForm() {
            this.formEnabled = false;
        },
        async saveForm(e) {
            e.preventDefault();

            this.formData.sale_id = this.formNo;
            if (!isAdmin()) {
                this.formData.user_id = null;
            }

            let loader = this.$loading.show();
            shippingService.formDataShipping = this.formData;
            let response = await shippingService.saveShipping();
            if (response.isSuccess) {
                this.formEnabled = false;
                Vue.$toast.open({message: response.message, type: 'success'});
                await this.loadFormShippingStatuses();
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        edit(shipping) {
            this.formData = shipping;
            this.showForm('edit', shipping)
        },
        async deleteShipping(data) {
            if (confirm("Are you sure that you want to delete this item?")) {
                let loader = this.$loading.show();
                let response = await shippingService.deleteShipping(data.id);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.loadFormShippingStatuses();
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
    },
    async created() {
        await this.loadShippingStatuses();
        await this.loadFormShippingStatuses();
        await this.loadUsers();
    }
}
</script>

<style scoped>

</style>