<template>
        <section class="content pl-2 pr-2">
            <CustomDataTable v-bind:tableDataUrl="tableDataUrl" v-bind:columns="columns" v-bind:searchFilters="searchFilters" v-bind:additionalButtons="additionalButtons" v-bind:tableSize="tableSize" ref="mainDataTable" v-if="showList"></CustomDataTable>
            <payment-method v-if="!showList" :save-handler="saveHandler" :id="singleId"></payment-method>
        </section>
</template>

<script>
    import Vue from 'vue';
    import CustomDataTable from "../table/CustomDataTable";
    import {getPermissionByKey} from "@/helpers/userHelper";
    import CustomDataTableDropDownButton from "@/components/app/table/CustomDataTableDropDownButton";
    import {PaymentService} from "@/services/payment-service";
    import PaymentMethod from "@/components/app/payment/PaymentMethod";

    let paymentService = new PaymentService();
    export default {
        name: 'PaymentMethods',
        data: function(){
            return {
                pageName: 'Payment Methods',
                segments: [
                    { link: true, text: 'Payments', routeName: 'App.Payments' },
                    { link: false, text: 'Payment Methods' },
                ],
                tableSize: 'col-sm-12',
                tableDataUrl: this.$globalSettings.api.endpoints.paymentMethod.paymentMethodsDatatable,
                columns: [
                    {label: 'ID', name: 'id', orderable: true, style: 'width: 10px'},
                    {label: 'Name', name: 'name', orderable: true, style: ''},
                    {
                        label: '',
                        style: 'width: 10px',
                        component: CustomDataTableDropDownButton,
                        meta: {
                            items: [
                                {
                                    label: 'Edit',
                                    showLabel: true,
                                    iconClass: 'fas fa-edit',
                                    event: "click",
                                    handler: this.edit,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.paymentMethod.paymentMethod_edit}],
                                    conditions: [],
                                },
                                {
                                    label: 'Delete',
                                    showLabel: true,
                                    iconClass: 'fas fa-trash',
                                    event: "click",
                                    handler: this.delete,
                                    dataPermissionConditions: [{column: 'editable', val: 1, permission: this.$globalSettings.permissions.paymentMethod.paymentMethod_delete}],
                                    conditions: [],
                                },
                            ]
                        }
                    },
                ],
                searchFilters:{
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc'
                },
                additionalButtons: [],

                showList: false,
                singleId: 0,
            }
        },
        props: {
            mode:{
                type: String,
                default: () => 'list'
            },
            showAddButtons:{
                type: Boolean,
                default: () => true
            },
        },
        components: {
            PaymentMethod, CustomDataTable
        },
        computed: {

        },
        methods: {
            add(){
                this.showList = false;
                this.singleId = 0;
            },
            edit(data){
                this.showList = false;
                this.singleId = data.id;
            },
            saveHandler: async function () {
                this.showList = true;
                this.singleId = 0;
            },
            async delete(data) {
                if (confirm("Are you sure that you want to delete this payment method?")) {
                    let loader = this.$loading.show();
                    let response = await paymentService.deletePaymentMethod(data.id);
                    if (response.isSuccess) {
                        await this.$refs.mainDataTable.loadTable();
                    }
                    Vue.$toast.open({message: response.message, type: response.status});
                    loader.hide();
                }
            }
        },
        mounted() {
            this.showList = this.mode === 'list';
            if(this.showAddButtons) {
                if (getPermissionByKey(this.$globalSettings.permissions.paymentMethod.paymentMethod_create)) {
                    this.additionalButtons.push({
                        label: 'Add',
                        buttonClass: 'btn btn-outline-secondary btn-sm float-right',
                        labelIconClass: 'fas fa-plus',
                        click: this.add
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
