import axios from "axios";
import {processHttpErrors} from "@/helpers/commonHelper";
import Vue from "vue";
import {getTimeZone} from "@/helpers/dateHelper";

export async function dashboardStats(){
    let timeZone = getTimeZone();
    return await axios.get(Vue.prototype.$globalSettings.api.endpoints.dashboard.statistics, {params: {timeZone: timeZone}})
        .then((response) => {
            return {isSuccess: true, message: null, report: response.data.statistics};
        })
        .catch((error) => {
            processHttpErrors(error, false);
            return {isSuccess: true, message: 'Something went wrong!', report: null};
        })
}