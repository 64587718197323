<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="row" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_list)">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="small-box bg-white">
                                    <div class="inner">
                                        <h3>{{statistics.sales_orders_today}}</h3>
                                        <div class="mb-2">Orders Today</div>
                                        <div class="icon">
                                            <i class="fas fa-cash-register"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="small-box bg-white">
                                    <div class="inner">
                                        <h3>{{statistics.sales_invoices_today}}</h3>
                                        <div class="mb-2">Invoices Today</div>
                                        <div class="icon">
                                            <i class="fas fa-file-invoice-dollar"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="small-box bg-white">
                                    <div class="inner">
                                        <h3>{{statistics.sales_delivery_today}}</h3>
                                        <div class="mb-2">Delivered Today</div>
                                        <div class="icon">
                                            <i class="fas fa-shipping-fast"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="small-box bg-white">
                                    <div class="inner">
                                        <h3>{{getCurrencyFormattedVal(statistics.sales_total_today)}}</h3>
                                        <div class="mb-2">Total Sales Today</div>
                                        <div class="icon">
                                            <i class="fas fa-dollar-sign"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">

                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_invoice_list)">
                        <div class="card">
                            <div class="card-header border-transparent pb-0">
                                Latest Sales Invoices
                            </div>
                            <div class="card-body p-0">
                                <Sales :show-ref="false" :show-pagination="false" :show-per-page="false" :show-search="false" :search-filters="salesList.searchFilters" :show-add-buttons="false" :show-print-buttons="false" :show-checkmark-buttons="false" :show-edit-button="false" :show-payments-button="false" :show-delete-button="false" :show-shipping-button="false"></Sales>
                                <div class="pl-3 pt-2 pb-3">
                                        <router-link :to="{name: 'App.Sale.POS'}" class="btn btn-outline-secondary btn-sm mr-1" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_invoice_create)"><i class="fas fa-cash-register"></i> POS</router-link>
                                        <router-link :to="{name: 'App.Sale', params: { id: 0, type: $globalEnums.formCategories.Invoice }}" class="btn btn-outline-secondary btn-sm mr-1" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_invoice_create)"><i class="fas fa-plus"></i> Invoice</router-link>
                                        <router-link :to="{name: 'App.Sale', params: { id: 0, type: $globalEnums.formCategories.Order }}" class="btn btn-outline-secondary btn-sm mr-1" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_order_create)"><i class="fas fa-plus"></i> Order</router-link>

                                    <span class="text-right" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_order_list) || getPermissionByKey($globalSettings.permissions.sale.sale_invoice_list) || getPermissionByKey($globalSettings.permissions.sale.sale_return_list)">
                                        <router-link :to="{name: 'App.Sales'}" class="btn btn-outline-secondary btn-sm mr-1"><i class="fas fa-search"></i> View All</router-link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" v-if="getPermissionByKey($globalSettings.permissions.payment.payment_list)">
                        <div class="card">
                            <div class="card-header border-transparent pb-0">
                                Latest Payments
                            </div>
                            <div class="card-body p-0">
                                <payments :show-ref="false" :show-type="true" :show-invoice="false" :show-method="true" :show-per-page="false" :show-search="false" :show-pagination="false" :show-add-buttons="false" :show-print-buttons="false" :show-view-button="true" :show-edit-button="false" :show-delete-button="false" :search-filters="paymentsList.searchFilters"></payments>
                                <div class="pl-3 pt-2 pb-3">
                                    <router-link :to="{name: 'App.Payment', params: { id: 0, type: $globalEnums.paymentTypes.Receive }}" class="btn btn-outline-secondary btn-sm mr-1" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_invoice_create)"><i class="fas fa-level-down-alt"></i> Receive</router-link>
                                    <router-link :to="{name: 'App.Payment', params: { id: 0, type: this.$globalEnums.paymentTypes.Pay }}" class="btn btn-outline-secondary btn-sm mr-1" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_order_create)"><i class="fas fa-level-up-alt"></i> Pay</router-link>
                                    <span class="text-right" v-if="getPermissionByKey($globalSettings.permissions.sale.sale_order_list) || getPermissionByKey($globalSettings.permissions.payment.payment_list)">
                                        <router-link :to="{name: 'App.Payments'}" class="btn btn-outline-secondary btn-sm mr-1"><i class="fas fa-search"></i> View All</router-link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../components/app/common/BreadCrumb";
    import Sales from "../../components/app/sale/Sales";
    import {dashboardStats} from "@/helpers/reportHelper";
    import {enums} from "@/static/enums";
    import {isAdmin, getPermissionByKey} from "@/helpers/userHelper";
    import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
    import Payments from "@/components/app/payment/Payments";

    export default {
        name: 'Dashboard_view',
        data: function(){
            return {
                pageName: 'Dashboard',
                segments: [],
                user: {},
                statistics:{
                    sales_orders_today: 0,
                    sales_invoices_today: 0,
                    sales_delivery_today: 0,
                    sales_total_today: 0
                },
                salesList: {
                    searchFilters: {
                        search: '',
                        length: 5,
                        column: 'date',
                        dir: 'desc',
                        type: enums.formCategories.Invoice
                    }
                },
                paymentsList: {
                    searchFilters: {
                        search: '',
                        length: 5,
                        column: 'payment_date',
                        dir: 'desc',
                    }
                },
            }
        },
        props: {},
        components: {
            Payments,
            Sales,
            BreadCrumb
        },
        computed: {
            
        },
        methods:{
            isAdmin,
            getPermissionByKey,
            getCurrencyFormattedVal,
            async loadSavedData() {
                let loader = this.$loading.show();
                let stats = await dashboardStats();
                if (stats.isSuccess) {
                    this.statistics = stats.report;
                }
                loader.hide();
            },
        },
        created() {
            this.user = this.$store.getters['auth/user'];
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>
