<template>
    <button :class="classes" @click="click(data, meta.params)" :title="meta.label" v-show="showButton">
        <i :class="meta.iconClass" v-if="meta.iconClass"></i>{{ meta.iconClass && meta.showLabel? ' ': '' + (meta.showLabel? meta.label: '') }}
    </button>
</template>

<script>
import {getPermissionByKey} from "@/helpers/userHelper";
    export default {
        name: "CustomDataTableDataConditionButton",
        data: function(){
            return{
                showButton: false
            }
        },
        props: {
            data: {},
            click: {
                type: Function,
                default: () => {}
            },
            classes: {
                type: Object,
                default: () => ({
                    'btn': true,
                    'btn-primary': true,
                    'btn-sm': true,
                }),
            },
            meta: {
                type: Object,
                default: () => ({
                    label: 'Button',
                    showLabel: true,
                    iconClass: 'fas fa-eye',
                    conditions: [],
                    dataPermissionConditions: [],
                    params: {},
                    editableCheck: false
                })
            }
        },
        created() {
            if(this.meta.conditions != undefined) {
                for (let i = 0; i < this.meta.conditions.length; i++) {
                    if (this.data[this.meta.conditions[i].key] === this.meta.conditions[i].val) {
                        this.showButton = true;
                    }
                }
            }
            if(this.meta.dataPermissionConditions != undefined) {
                for (let i = 0; i < this.meta.dataPermissionConditions.length; i++) {
                    if (this.data[this.meta.dataPermissionConditions[i].column] === this.meta.dataPermissionConditions[i].val) {
                        if (getPermissionByKey(this.meta.dataPermissionConditions[i].permission)) {
                            this.showButton = true;
                            break;
                        }
                    }
                }
            }
            if(this.meta.editableCheck){
                if(!this.data.editable){
                    this.showButton = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>